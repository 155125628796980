import React from 'react'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'
import { PageWidths, Colors, Fonts } from '../utils/layoutUtils'
import Cover from '../components/cover'
import ConvertKitForm from 'convertkit-react'

class Newsletter extends React.Component {
  render() {
    const {
      data: { site, logo },
      location,
    } = this.props

    const siteTitle = site.siteMetadata.title
    const convertKitFormId = site.siteMetadata.convertKitFormId

    return (
      <Layout location={location} title={siteTitle} pageWidth={PageWidths.WIDE}>
        <SEO title="AWS Maniac Newsletter" url="/newsletter/" />

        <h1 css={css`margin-top: 0; text-align: center;`}>Are you struggling with <em>AWS Madness</em>?<br/><strong>AWS Maniac</strong> to the rescue!</h1>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: ${rhythm(3)};
            margin-bottom: ${rhythm(3)};
            justify-content: center;
          `}
        >
          <Cover fixedImage={logo.childImageSharp.fixed} />

          <div
            css={css`
              font-size: 1.1rem;
              padding: 0 ${rhythm(1)};
              display: flex;
              flex-direction: column;
              text-align: center;
              max-width: ${rhythm(20)};
              padding-top: ${rhythm(1)};
            `}
          >
            <p>
              <strong>
                Join the newsletter, learn about one service from <a href="https://aws.amazon.com" target="_blank">AWS</a> portfolio each Saturday, and be notified each time new post will be published here.
              </strong>
            </p>
            <div
              css={css`
                .convertkit-form {
                  font-family: ${Fonts.SANS} !important;
                }

                .convertkit-form button {
                  background-color: ${Colors.MAIN} !important;
                  font-weight: bold !important;
                  text-transform: uppercase;
                }

                .convertkit-form a {
                  display: none !important;
                }
              `}
            >
              <ConvertKitForm className="convertkit-form" template="clare" stacked={false} formId={convertKitFormId} />
            </div>
          </div>
        </div>

        <div>
          <p>
            My mission is to be a trusted source of knowledge about the AWS platform and services there.
          </p>
          <p>
            I will share my knowledge, best practices, and insights with you - everything in a pragmatic way, supported by hands-on experience.
          </p>
          <p>
            The landscape is broad and diverse that I am sure you will learn a thing or two, no matter the expertise level you have.
          </p>

          <p>
            Plus, you will get additionally:
          </p>
          <ul>
            <li>
              You will have priority access when it comes to participation in the new initiative I will organize. This may include online training, webinar, course, workshop, and other types of events. 👷
            </li>
            <li>
              I will always be just one click of the <em>Reply</em> button away. It means you can ask me anytime about something you are struggling with or are interested in, and I will do my best to help you. 🤙
            </li>
            <li>
              It is voluntary, so you can unsubscribe. <em>No questions asked</em>. 🤷‍♂️
            </li>
            <li>
              Nobody likes <em>spam</em>. That is why I will send you a message only if it provides value to you. 👍
            </li>
          </ul>

          <p>
            <Link to="/why-email/">Here</Link> you can learn why I need you to subscribe and provide your email to receive my materials.
          </p>

          <p>
            <Link to="/gmail/">Here</Link> you can find information on how to ensure the visibility of my emails.
          </p>

          <p>
            <small>
              Last but not least, here you will find all formalities necessary for the <em>GDPR</em>: <Link to="/privacy-policy/">Privacy Policy</Link>.
            </small>
          </p>

        </div>
      </Layout>
    )
  }
}

export default Newsletter

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        convertKitFormId
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 200, height: 200, quality: 100) {
          ...GatsbyImageSharpFixed
        },
      }
    }
  }
`
